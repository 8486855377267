import * as React from 'react';
import Layout from '../../components/layout';
// import '../styles/about.css';
import '../../styles/level1bliss.css';
import { StaticImage } from 'gatsby-plugin-image';

const Level3Bliss = () => {
   return (
      // <Layout pageTitle="About Me">
      <>
         <Layout>
            {/* <div className="theContainer"> */}
            {/* <h1 className="theTitle">About Me</h1>
               <p className="theSubtitle">
                  Living and walking in the love of God.
               </p> */}
            {/* </div> */}
            {/* </Layout> */}
            <div className="contentArticle">
               <br />
               <br />
               <div className="textCategory">Mystical Union</div>
               <br />
               <div className="textTitle">Living in the Bliss</div>
               <div className="textSubtitle">SubTitle</div>
               <br />
               <div className="textImgCenter">
                  {/* img-center */}
                  <StaticImage
                     alt="StJoseph"
                     src="../images/grapes.jpeg"
                     width="250"
                     height="250"
                     // imgStyle={{ borderRadius: '15px 15px 0px 0px' }}
                     imgStyle={{ borderRadius: '50%' }}
                  />
               </div>
               <br />
               <br />
               <br />
               <div className="textText">
                  Text word word{' '}
                  <a href="disney.com" className="textAhref">
                     word word word
                  </a>{' '}
                  word word word word word word. Word word word word word word
                  word word word word word. Word word word word word word word
                  word word word word. Word word word word word word word word
                  word word word.
               </div>
               <br />
               <div className="textQuote">
                  quote quote quote quote quote quote quote quote
               </div>
               <div className="textDivider">{/* subdivider */}</div>
               <br />
               <br />
               <div className="textText">
                  Text word word word word word word word word word word word.
                  Word word word word word word word word word word word. Word
                  word word word word word word word word word word. Word word
                  word word word word word word word word word. Word word word
                  word word word word word word word word. Word word word word
                  word word word word word word word. Word word word word word
                  word word word word word word. Word word word word word word
                  word word word word word. Word word word word word word word
                  word word word word. Word word word word word word word word
                  word word word. Word word word word word word word word word
                  word word. Word word word word word word word word word word
                  word. Word word word word word word word word word word word.
                  Word word word word word word word word word word word. Word
                  word word word word word word word word word word. Word word
                  word word word word word word word word word.
               </div>
               <br />
               <div className="textQuote">
                  quote quote quote quote quote quote quote quote
               </div>
               <div className="textDivider">{/* subdivider */}</div>
               <br />
               <br />
               <div className="textText">
                  Text word word word word word word word word word word word.
                  Word word word word word word word word word word word. Word
                  word word word word word word word word word word. Word word
                  word word word word word word word word word. Word word word
                  word word word word word word word word. Word word word word
                  word word word word word word word. Word word word word word
                  word word word word word word. Word word word word word word
                  word word word word word. Word word word word word word word
                  word word word word. Word word word word word word word word
                  word word word. Word word word word word word word word word
                  word word. Word word word word word word word word word word
                  word. Word word word word word word word word word word word.
                  Word word word word word word word word word word word. Word
                  word word word word word word word word word word. Word word
                  word word word word word word word word word.
               </div>
               <br />
               <div className="textLongQuote">
                  Quote quote quote quote quote quote quote quote. Quote quote
                  quote quote quote quote quote quote. Quote quote quote quote
                  quote quote quote quote. Quote quote quote quote quote quote
                  quote quote. Quote quote quote quote quote quote quote quote.
                  Quote quote quote quote quote quote quote quote. Quote quote
                  quote quote quote quote quote quote. Quote quote quote quote
                  quote quote quote quote. Quote quote quote quote quote quote
                  quote quote. Quote quote quote quote quote quote quote quote.
                  Quote quote quote quote quote quote quote quote. Quote quote
                  quote quote quote quote quote quote.
               </div>
               <br />
               <br />
               <br />
               <br />
               <br />
            </div>
         </Layout>
      </>
   );
};

export default Level3Bliss;
